import React from 'react'

const SvgArrow = props => (
  <svg width={31} height={21} viewBox="0 0 31 21" {...props}>
    <path
      fill="#F5F2ED"
      d="M21.179.58c-.096.338-.018.714.198.951l7.103 8.104H.671c-.368.022-.688.457-.67.915.018.458.368.856.736.834h27.744l-7.104 8.09a.985.985 0 00-.234.606c-.01.232.057.46.182.632a.655.655 0 00.49.287.627.627 0 00.508-.227l8.445-9.62a.984.984 0 00.232-.65.984.984 0 00-.232-.649L22.323.233A.635.635 0 0021.817 0c-.289.013-.54.242-.638.579z"
    />
  </svg>
)

export default SvgArrow
