/**
 * Host Scripts Page
 * 
 * Page that displays all of the links to relevant host scripts for that
 * particular hunt. Can link to outside websites or files (ie PDFs, documents).
 */

import React, { useContext, useState, useEffect } from 'react'
import HostLoginWrapper from '../../components/host/login-wrapper'
import HostContext from '../../utils/host-context'
import API from '../../utils/api'
import SvgArrow from '../../components/svg/arrow'
import { Link } from 'gatsby'

const HostScriptsPage = ({location}) => {
  const context = useContext(HostContext)
  const [hunt, setHunt] = useState()
  const [updating, setUpdating] = useState(false)

  useEffect(() => {
    const hunt_id = location.hash.substring(1)
    if(!updating){
      setUpdating(true)
      API.get(`/host/hunt/${hunt_id}/`, {
        headers: {
          Authorization: `JWT ${localStorage.getItem(`token`)}`,
        },
      }).then((res) => {
        console.log(res)
        setUpdating(false)
        setHunt(res.data)
        context.setActiveHunt(res.data)
      })
    }
  }, [])

  return (
    <HostLoginWrapper>
      <div className="site-sub-header px-4 py-4 mb-4">
        <div className="row">
          <div className="col-2 d-flex align-items-center">
            <Link to={hunt ? `/host/hunt/#${hunt.id}` : `/host/`} className={`back-arrow`}>
              <SvgArrow />
            </Link>
          </div>
          <div className="col-10 text-right">
            {hunt && <h1 className={`title h5 text-uppercase mb-0`}>
              {hunt.display_title ? hunt.display_title : hunt.title} Scripts
            </h1>}
          </div>
        </div>
      </div>
      <div className="content px-4 pb-5">
        {hunt && <div className="card">
          <div className="card-header">
            <h3>Scripts</h3>
          </div>
          <div className="card-body">
            {hunt.hunt_scripts &&
              <ul className="scripts-wrapper list-unstyled text-larger">
                {hunt.hunt_scripts.map((item, idx) => {
                  var url = item.url
                  if(item.file_url){
                    url = item.file_url
                  }
                  return (<li key={`script-${idx}`} className="mb-3"><a href={url} target="_blank" rel="noreferrer" className="h4">{item.title}</a></li>)
                })}
              </ul>
            }
          </div>
        </div>}
      </div>
    </HostLoginWrapper>
  )
}

export default HostScriptsPage